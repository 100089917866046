import React, {Component} from "react";
import AsyncSelect from 'react-select/async';
import Request, {CODES} from '../../utils/Request';



class InputIdProduct extends Component {
	constructor(props){
		super(props);

		this.state = {
			status : 'success',
			inputMessage : '',
			value: this.props.form.getValue(this.props.name) || this.props.defaultValue ,
			currentLabel: '',
		}

		this.validateInput = this.validateInput.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
		this.loadOptions = this.loadOptions.bind(this);
	}

	componentDidMount(){
		let value = this.props.form.getValue(this.props.name);
		if((value == "" || value == undefined) && this.props.defaultValue !== undefined){
			this.props.form.setValue(this.props.name, this.props.defaultValue);
			value = this.props.defaultValue;
		}
		this.validateInput(value);
	}

	componentWillUnmount(){
		this.props.form.setValidate(this.props.name, true);
	}

	validateInput(value){
		let valid = true;
		if(this.props.required && ( value === undefined || value === "")){
			valid = false;
			this.setState({
				status: "error",
				inputMessage: this.props.name + ' is required',
			});
		}else{
			this.setState({
				status: "success",
				inputMessage: '',
			})
		}

		this.props.form.setValidate(this.props.name, valid);
	}

	handleOnChange(option){
		this.validateInput(option.value);
		this.props.form.setValue(this.props.name, option.value);
		this.setState({currentLabel:option.label});
	}

	loadOptions(inputValue,  callback){
		// if(inputValue.length > 2){
			let params = this.props.extra || {};
			params['sku'] = inputValue;
			Request.getAuth('/api/products/searcher/?'+new URLSearchParams(this.props.extra).toString()).then( res => {
				if(res.code === CODES.success){
					let options = [];
					let label = this.state.label;
					for(let product of res.products){
						options.push({
							value: product.id,
							label: product.sku,
						});
						if(!label && this.state.value == product.id){
							label = product.sku;
						}
					}
					callback(options);
					if(label != this.state.label){
						this.setState({currentLabel:label});
					}
				}
			});
		// }else{
		// 	callback([]);
		// }
	};

	render(){
		let statusClass = (this.state.status === "error") ? "input-error" : "";
		let readOnlyClass = (this.props.readOnly) ? " read-only" : "";
		let value = this.props.form.getValue(this.props.name) || this.props.defaultValue;
		let input = 		
		    <label className={"font-p input-container "+statusClass+readOnlyClass} data-message={this.state.inputMessage}>
		    	{(this.props.label !== undefined)? 
		    		(<span className="label-text">{this.props.label + ": "}</span>)
		    		:
		    		(null)
		    	}
		    	<AsyncSelect cacheOptions loadOptions={this.loadOptions} defaultOptions
		    		// noOptionsMessage={(input)=>((input.inputValue.length > 2)?"No encontrado":"Escribe al menos 3 caracteres")}
					className="input-options-container select-custom-container"
					classNamePrefix="select-custom"
					onChange={this.handleOnChange}
					value = {(this.props.form.getValue(this.props.name)) ?
					 	{
							value: this.props.form.getValue(this.props.name),
							label: this.state.currentLabel,
						}: null}
		    	/>
	        </label>
		return input;
	}
}

export default InputIdProduct;