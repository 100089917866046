import React, {Component} from "react";
import { Link, Redirect } from "react-router-dom";

import pageList from './pageList';
import Auth from "../../utils/Auth";
import Request, {CODES} from '../../utils/Request';

import ConfirmPopup from "../components/confirmPopup";
import { DeleteSvg, CloseSvg } from '../../utils/svgs';


class editListAjax extends pageList {

	constructor(props) {
		super(props);
		this.errorMessage = 'Escribe al menos 3 caracteres para mostrar los resultados';
	}

	requestPages(){
		this.responseField = this.responseField || this.apiName;
		this.listTitle = this.listTitle || this.responseField;
		this.itemLinkSlug = this.itemLinkSlug || this.listSlug
		this.list = "";
		this.setPages([]);
	}

	handleChangeSearchText(event){
		if(event.target.value && event.target.value.length > 2){
			Request.getAuth('/api/'+this.apiName+'?s='+event.target.value)
				.then(json => {
					this.errorMessage = 'No se han encontrado resultados';
					if( json.code === CODES.success ){
						this.setPages(json[this.responseField]);
					}
			});
		}else{
			this.errorMessage = 'Escribe al menos 3 caracteres para mostrar los resultados';
			this.setPages([]);
		}
	}
}

export default editListAjax;