import React from "react";
import {NavLink, Link} from "react-router-dom";
import pageList from './pageList';
import Request, {CODES} from '../../utils/Request';

// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";


class pageListStock extends pageList {
	
	constructor(props){
		super(props)
		this.apiName = 'stock';
		this.listSlug = 'stock';
		this.singleItem = 'stock'; //Name of a single list item
		this.listTitle = 'Stock';

		this.hasRowOptions = false;
	}
	
	componentDidMount(){
		this.requestPages();
	}

	getTable(pages){
		let table = {};
		table.headers = [
			{
				slug: 'brand',
				name: 'Brand',
				class: 'item-medium',
				canSearch: true,
			},
			{
				slug: 'name',
				name: 'Nombre',
				class: 'item-big',
				canSearch: true,
			},
			{
				slug: 'sku',
				name: 'SKU',
				class: 'item-big',
				canSearch: true,
			},
			{
				slug: 'sku_soultek',
				name: 'SKU Soultek',
				class: 'item-big',
				canSearch: true,
			},
			{
				slug: 'sku_stk',
				name: 'SKU STK',
				class: 'item-big',
				canSearch: true,
			},
			{
				slug: 'provider',
				name: 'Proveedor',
				class: 'item-medium',
				canSearch: true,
			},
			{
				slug: 'stock',
				name: 'Stock',
				class: 'item-medium',
				canSort: true,
			},
			{
				slug: 'stock_almacen',
				name: 'Stock almacen',
				class: 'item-medium',
				canSort: true,
			},
			{
				slug: 'location_store',
				name: 'Almacen',
				class: 'item-medium',
			},
		];
		table.rows = [];
		for(let i = 0; i < pages.length; i++){
			table.rows.push(this.createRow(pages[i]));
		}
		return table;
	}

	createRow(page){
		// if(page.is_composite){
		// 	page.stock_almacen = '-';
		// }
		return {
			item : page,
			brand : page.brand,
			sku : page.sku,
			name : page.name,
			sku_soultek : page.sku_soultek,
			sku_stk : page.sku_stk,
			provider : page.provider,
			stock : page.stock,
			stock_almacen : page.stock_almacen,
			location_store: page.location_store,
		};
	}

	renderBeforeAddNew(){
		return <Link to={'/remove-stock/'} className="font-p button">Retirar Stock</Link>;
	}
	renderAddNew(){
		return <Link to={'/add-stock/'} className="font-p button">Añadir Stock</Link>;
	}
}





export default pageListStock;




